module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Your fastest path to meaningful insights | Prompt Studio","short_name":"Prompt Studio","description":"Turn data into meaningful insights quickly using Promptbooks, our collaborative AI-powered notebooks for data analysis.","icon":"./src/images/logo.svg","icon_options":{"purpose":"any maskable"},"background_color":"#f4f2ec","theme_color":"#0A0D15","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"464ce362bd21ebb45069fd1a85f3b244"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
